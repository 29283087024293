<template>
  <div
    class="absolute py-2 px-3 rounded-md bg-white flex items-center justify-center"
    :class="tooltipClass"
  >
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
  },
  computed: {
    tooltipClass() {
      if (this.position === 'bottom') {
        return '-bottom-[60px] -left-full'
      }

      return 'bottom-0'
    },
  },
}
</script>
