<template>
  <modal @close="$emit('close')">
    <div class="text-2xl">
      แสดงคำขอบคุณ หรือให้กำลังใจ  
      <p class="text-banyen">"{{ userNickName }}"</p>
      ผ่านการเลี้ยงเครื่องดื่ม
    </div>
    <div class="flex mt-8 justify-center">
      <div
        v-for="option in tipOptions"
        :key="option.id"
        class="relative border-4 border-black rounded-xl flex flex-col items-center w-28 mx-1 cursor-pointer"
        @click="selectedOption = option.id"
      >
        <div class="flex items-center text-banyen text-sm mt-2">
          + {{ option.amount }}ติ๊บ
          <img
            src="/tip/tip-icon.png"
            class="w-5 h-5 ml-1"
          >
        </div>
        <img
          :src="option.image"
          class="mb-2"
          :class="option.amount === 100 ? 'w-16' : 'w-12'"
        >
        <div
          v-show="option.isMostPopular"
          class="text-banyen absolute bottom-4"
        >
          Most Popular
        </div>
        <div
          class="absolute bottom-[-15px] text-lg rounded-3xl border-black border-2 px-3"
          :class="selectedOption === option.id ? 'bg-banyen' : 'bg-cream-blue'"
        >
          {{ option.amount }} บาท
        </div>
      </div>
    </div>
    <div class="text-xl mt-8">
      *เครื่องดื่มจะกลายเป็นติ๊บให้
    </div>
    <div class="text-xl">
      บาร์เทนเดอร์ไว้ใช้สอยในเว็บบาร์ทิพย์
    </div>
    <div class="text-xl">
      และเป็นการสนับสนุนแพลตฟอร์มด้วยน้า
    </div>
    <div class="flex justify-center mt-8">
      <button
        class="bg-cream-blue text-lg border-black border-2 rounded-3xl px-6 py-1 mr-2"
        @click="$emit('close')"
      >
        ผ่านก่อน
      </button>
      <button
        class="bg-banyen text-lg border-black border-2 rounded-3xl px-6 py-1"
        @click="onTipClicked"
      >
        เลี้ยง!
      </button>
    </div>
  </modal>
</template>

<script>
import BanyenButton from '../common/BanyenButton.vue'
import global from '~/mixins/global.js'
import Modal from '~/components/common/Modal.vue'

export default {
  components: {
    Modal,
    BanyenButton,
  },
  mixins: [global],
  props: {
    userId: {
      type: String,
      required: true,
    },
    userNickName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tipOptions: [
      {
        id: 'cocktail-1',
        image: '/tip/cocktail-1.png',
        amount: 30,
      },
      {
        id: 'cocktail-2',
        image: '/tip/cocktail-2.png',
        isMostPopular: true,
        amount: 50,
      },
      {
        id: 'cocktail-3',
        image: '/tip/cocktail-3.png',
        amount: 100,
      },
    ],
    selectedOption: null,
  }),
  methods: {
    async onTipClicked() {
      if (!this.selectedOption) {
        this.$notify({
          type: 'error',
          text: 'กรุณาเลือกแก้วที่ต้องการเลี้ยงก่อน',
        })
        return
      }
      try {
        this.$store.setPageLoading(true)
        const data = await $fetch('/api/payments/checkout', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          body: { productCode: this.selectedOption, toUserId: this.userId },
          headers: this.headers,
        })

        window.location = data.url
      }
      catch (error) {
        this.$notify({
          type: 'error',
          message: 'เกิดข้อผิดพลาดขณะซื้อ Cocktail ให้เพื่อนกรุณาลองใหม่อีกครั้งค่ะ',
        })
      }
      this.$store.setPageLoading(false)
    },
  },
}
</script>
