<template>
  <div>
    <tip-modal v-if="showTipModal" :user-id="currentUserProfile.id" :user-nick-name="currentUserProfile.nickName"
      @close="showTipModal = false" />
    <report-confirmation-modal v-if="showReportConfirmationModal" :member-to-report="memberToReport"
      @close="onCloseReport" />
    <unfriend-confirmation-modal v-if="showUnfriendConfirmationModal" :current-user-profile="currentUserProfile"
      @close="showUnfriendConfirmationModal = false" @confirm="onUnfriendConfirmed" />
    <div
      v-if="currentUserProfile"
      class="fixed top-0 left-0 right-0 bottom-0 z-[50] flex justify-center items-center px-3 h-screen">
      <div class="relative dom-border modal-body justify-center bg-cream">
        <div class="bg-cream-blue w-full h-full border-b-4 border-black rounded-b-xl">
          <div class="flex justify-start px-12 py-6 flex-col items-start">
            <div class="flex flex-col items-start lg:flex-row">
              <div v-if="currentUserProfile.yourFriendStatus === 'accepted'"
                class="flex relative items-center bg-shade-blue rounded-3xl px-3 py-1 mr-2">
                <img src="/counter-page/member-5.png" class="h-7 relative bottom-1">
                <img src="/counter-page/member-5.png" class="h-7 relative bottom-1 -scale-x-100 -ml-4">
                เป็นเพื่อนแล้ว
              </div>
              <div v-if="currentUserProfile.yourFriendStatus === 'accepted'" class="w-2 h-2"/>
              <div class="flex relative items-center bg-shade-blue rounded-3xl px-3 py-1 mr-2">
                <img src="/counter-page/member-5.png" class="h-7 relative bottom-1">
                เพื่อน
                <div class="text-lg ml-2">
                  x{{ currentUserProfile.friendsCount }}
                </div>
              </div>
              <div class="w-2 h-2"/>
              <div class="flex items-center bg-shade-blue rounded-3xl px-3 py-1 mr-6">
                <img src="/tip/cocktail-icon.png" class="h-8 w-8">
                แก้วที่มีคนเลี้ยง
                <div class="text-lg ml-2">
                  x{{ currentUserProfile.tipsReceipedCount }}
                </div>
              </div>
            </div>
            <img data-testid="close-modal" src="/close-modal.png" class="absolute top-4 right-4 w-9 cursor-pointer" @click="$emit('close')">
            <div class="flex items-start mt-4">
              <div class="mr-4">
                <avatar
                  :user-detail="currentUserProfile"
                  :showDrinkDescription=false
                  :drink-id="currentUserProfile.drinkId"
                />
              </div>
              <div class="flex flex-col">
                <p class="text-4xl">
                  {{ currentUserProfile.nickName }}
                </p>
                <p v-if="currentUserProfile.username"class="text-xl text-paragraph-gray">
                  @{{ currentUserProfile.username }}
                </p>
                <div v-if="currentUserProfile.interests" class="mt-4">
                  <p class="text-xl font-semibold">ความสนใจ</p>
                  <p class="text">
                    {{ currentUserProfile.interests }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="userId !== user.id" class="px-12 py-6">
          <div v-if="currentUserProfile.yourFriendStatus === 'pending'">
            <div class="flex justify-center items-center text-xl mb-4">
              {{ currentUserProfile.nickName }} ขอคุณเป็นเพื่อน
            </div>
            <div class="flex justify-end gap-2">
              <button 
                class="flex items-center px-3 py-1 bg-banyen border-black border-2 rounded-3xl"
                @click="onAcceptFriendRequestClicked">
                <p>รับเป็นเพื่อน</p>
              </button>
              <button
                class="flex items-center px-3 py-1 bg-cream-blue border-black border-2 rounded-3xl"
                @click="onRejectFriendRequestClicked">
                <p>ปฏิเสธ</p>
              </button>
            </div>
          </div>
          <div v-else class="flex justify-center lg:flex-row flex-col items-start">
            <button v-if="currentUserProfile.yourFriendStatus === 'accepted'"
              class="flex items-center px-3 py-1 bg-cream-blue border-black border-2 rounded-3xl"
              @click="onUnfriendClicked">
              <img src="/counter-page/member-5.png" class="h-7 relative bottom-1 -scale-x-100 -mr-1">
              <div class="text-lg text-red-500">x</div>
              <img src="/counter-page/member-5.png" class="h-7 relative bottom-1 -ml-1">
              <p>ยกเลิกเป็นเพื่อน</p>
            </button>
            <button v-else-if="currentUserProfile.yourFriendStatus === 'none' || currentUserProfile.yourFriendStatus === 'rejected'"
              class="flex items-center px-3 py-1 bg-cream-blue border-black border-2 rounded-3xl"
              @click="onFriendRequestClicked">
              <img src="/counter-page/member-5.png" class="h-7 relative bottom-1">
              <img src="/counter-page/member-5.png" class="h-7 relative bottom-1 -scale-x-100 -ml-4">
              <p>ขอเป็นเพื่อน</p>
            </button>
            <div class="w-2 h-2"/>
            <button v-if="!hideTipButton" class="flex items-center px-3 py-1 bg-cream-blue border-black border-2 rounded-3xl"
              @click="onTipClicked">
              <img src="/tip/cocktail-icon.png" class="h-8 w-8">
              <p>เลี้ยงเครื่องดื่ม</p>
            </button>
            <div class="w-2 h-2"/>
            <button v-if="!hideReportButton" class="flex items-center px-3 py-1 bg-cream-blue border-black border-2 rounded-3xl"
              @click="onReportClicked">
              <img src="/report/report-flag.png" class="h-8 w-8 mr-1">
              <p>รีพอร์ต</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '~/mixins/global.js';
import Avatar from '~/components/common/Avatar.vue';
import TipModal from '~/components/counter/TipModal.vue';
import ReportConfirmationModal from '~/components/report/ReportConfirmationModal.vue';
import UnfriendConfirmationModal from '~/components/UnfriendConfirmationModal.vue';

export default {
  components: {
    Avatar,
    ReportConfirmationModal,
    TipModal,
    UnfriendConfirmationModal,
  },
  mixins: [global],
  props: {
    userId: {
      type: String,
      required: true,
    },
    hideTipButton: {
      type: Boolean,
      default: false,
    },
    hideReportButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    memberToReport: {},
    showReportConfirmationModal: false,
    showTipModal: false,
    currentUserProfile: null,
    showUnfriendConfirmationModal: false,
  }),
  async mounted () {
    this.$store.setPageLoading(true)
    try {
      const data = await $fetch(`/api/user/${this.userId}/profile`, {
        method: 'GET',
        baseURL: this.$config.public.appBaseURL,
        headers: this.headers,
      })
      this.currentUserProfile = data
    } catch (error) {
      console.error(error)
    }
    this.$store.setPageLoading(false)
  },
  methods: {
    onUnfriendClicked () {
      this.showUnfriendConfirmationModal = true;
    },
    onUnfriendConfirmed () {
      this.$store.setPageLoading(true)
      try {
        $fetch('/api/friend/remove', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
          body: {
            userId: this.userId,
          },
        });
        this.currentUserProfile.yourFriendStatus = 'none'
      } catch (error) {
        console.error(error);
      }
      this.$store.setPageLoading(false)
      this.showUnfriendConfirmationModal = false;
      this.$emit('unfriend-confirmed', this.userId);
    },
    async onFriendRequestClicked () {
      try {
        await $fetch('/api/friend/add', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
          body: {
            userId: this.userId,
          },
        })
      } catch (error) {
        switch (error?.response?._data?.message) {
          case 'friend-limit-reached':
            this.$notify({
              type: 'error',
              text: 'คุณมีเพื่อนเกินจำนวนที่กำหนดไว้แล้ว',
            })
            break
          case 'other-friend-limit-reached':
            this.$notify({
              type: 'error',
              text: 'คนที่คุณต้องการเพิ่มเพื่อน มีเพื่อนเกินจำนวนที่กำหนดไว้แล้ว',
            })
            break
        }
        console.error(error);
      }
      this.$emit('close');
    },
    async onAcceptFriendRequestClicked () {
      this.$store.setPageLoading(true)
      try {
        await $fetch('/api/friend/accept', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
          body: {
            userId: this.userId,
          },
        })
        this.currentUserProfile.yourFriendStatus = 'accepted'
        this.$store.setPageLoading(false)
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'เกิดข้อผิดพลาดในการรับเป็นเพื่อน',
        })
        console.error(error);
      }
      this.$store.setPageLoading(false)
    },
    async onRejectFriendRequestClicked () {
      this.$store.setPageLoading(true)
      try {
        await $fetch('/api/friend/reject', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
          body: {
            userId: this.userId,
          },
        })
        this.currentUserProfile.yourFriendStatus = 'rejected'
        this.$store.setPageLoading(false)
      } catch (error) {
        this.$store.setPageLoading(false)
        this.$notify({
          type: 'error',
          text: 'เกิดข้อผิดพลาดในการปฏิเสธเป็นเพื่อน',
        })
        console.error(error);
      }
    },
    onReportClicked () {
      this.memberToReport = {
        userId: this.currentUserProfile.id,
        nickName: this.currentUserProfile.nickName,
      };
      this.showReportConfirmationModal = true;
    },
    onTipClicked () {
      this.showTipModal = true;
    },
    onCloseReport () {
      this.showReportConfirmationModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}
</style>
