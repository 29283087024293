<template>
  <div class="fixed top-0 z-[80] w-screen h-screen flex flex-col justify-center bg-white items-center">
    <div>
      <img
        class="w-32 mx-auto"
        src="/bartender-shake.gif"
      >
      <p class="text-lg text-white">
        กำลังโหลด. . .
      </p>
    </div>
  </div>
</template>
